import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userLogoutAction } from "../../actions/institute/Authentication/userAuthenticationAction";
import { clearRoleAction } from "../../actions/settingsAction";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  handleLogout = () => {
    this.props.userLogoutAction();
    this.props.clearRoleAction();
    this.props.history.push("/");
  };
  render() {
    return (
      <div className="container-fluid">
        <div className="page-header">
          <div className="left"></div>
          <div className="right">
            <Navbar color="white" light expand="md">
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="mr-4" navbar>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                      <span className="nav-link icon d-none d-md-flex btn btn-default btn-icon">
                        <i className="fa fa-bell"></i>
                        <span className="badge badge-primary nav-unread"></span>
                      </span>
                    </DropdownToggle>
                    {localStorage.getItem("loginedRole") ===
                      "institute admin" && (
                      <DropdownMenu right>
                        <DropdownItem
                          tag={Link}
                          to="/institute/manage-circular"
                        >
                          Manage Circular
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          to="/institute/manage-feedback"
                        >
                          Manage Feedback
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/institute/manage-events">
                          Manage Event
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          to="/institute/manage-announcement"
                        >
                          Manage Announcement
                        </DropdownItem>
                      </DropdownMenu>
                    )}
                    {localStorage.getItem("loginedRole") === "principal" && (
                      <DropdownMenu right>
                        <DropdownItem
                          tag={Link}
                          to="/institute/manage-circular"
                        >
                          Manage Circular
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          to="/institute/manage-feedback"
                        >
                          Manage Feedback
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/institute/manage-events">
                          Manage Event
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          to="/institute/manage-announcement"
                        >
                          Manage Announcement
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/institute/manage-gallery">
                          Manage Gallery
                        </DropdownItem>
                      </DropdownMenu>
                    )}
                    {localStorage.getItem("loginedRole") === "teacher" && (
                      <DropdownMenu right>
                        <DropdownItem
                          tag={Link}
                          to="/teacher/manage-assignment"
                        >
                          Manage Assignment
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/teacher/manage_gallery">
                          Manage Gallery
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          to="/teacher/manage-announcement"
                        >
                          Manage Announcement
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/teacher/manage-goal">
                          Manage Goals
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          to="/teacher/manage-achievement-rule"
                        >
                          Manage Achievement
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/teacher/manage-skill">
                          Manage Skills
                        </DropdownItem>
                      </DropdownMenu>
                    )}
                    {localStorage.getItem("loginedRole") === "student" && (
                      <DropdownMenu right>
                        <DropdownItem
                          tag={Link}
                          to="/student/manage-chat-screen"
                        >
                          Manage Group Chat
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          to="/student/manage-assignment"
                        >
                          Manage Assignment
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/student/view-events">
                          Manage Event
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          to="/student/view-announcement"
                        >
                          Manage Announcement
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/student/manage_gallery">
                          Manage Gallery
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/student/study_material">
                          Study Material
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/student/online-meeting">
                          Online Meeting
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/student/manage-goal">
                          Manage Goals
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          to="/student/view-achievements"
                        >
                          Manage Achievement
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/student/manage-skills">
                          Manage Skills
                        </DropdownItem>
                      </DropdownMenu>
                    )}
                    {localStorage.getItem("loginedRole") === "parent" && (
                      <DropdownMenu right>
                        <DropdownItem tag={Link} to="/parent/view-circulars">
                          Manage Circular
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/parent/view-events">
                          Manage Event
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/parent/view-announcement">
                          Manage Announcement
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/parent/manage-goal">
                          Manage Goals
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/parent/manage-skills">
                          Manage Skills
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/parent/manage-assignment">
                          Manage Assignment
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          to="/parent/manage-examination"
                        >
                          Exam evaluation & result
                        </DropdownItem>
                      </DropdownMenu>
                    )}
                  </UncontrolledDropdown>
                  {localStorage.getItem("loginedRole") ===
                    "institute admin" && (
                    <i
                      onClick={() =>
                        this.props.history.push("/institute/my-profile")
                      }
                      style={{
                        backgroundImage: "url(../assets/images/xs/avatar5.jpg)",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      className="avatar"
                    ></i>
                  )}
                  {localStorage.getItem("loginedRole") ===
                    "principal" && (
                    <i
                      onClick={() =>
                        this.props.history.push("/institute/my-profile")
                      }
                      style={{
                        backgroundImage: "url(../assets/images/xs/avatar5.jpg)",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      className="avatar"
                    ></i>
                  )}
                   {localStorage.getItem("loginedRole") ===
                    "teacher" && (
                    <i
                      onClick={() =>
                        this.props.history.push("/teacher/my-profile")
                      }
                      style={{
                        backgroundImage: "url(../assets/images/xs/avatar5.jpg)",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      className="avatar"
                    ></i>
                  )}
                   {localStorage.getItem("loginedRole") ===
                    "student" && (
                    <i
                      onClick={() =>
                        this.props.history.push("/student/my-profile")
                      }
                      style={{
                        backgroundImage: "url(../assets/images/xs/avatar5.jpg)",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      className="avatar"
                    ></i>
                  )}
                   {localStorage.getItem("loginedRole") ===
                    "parent" && (
                    <i
                      onClick={() =>
                        this.props.history.push("/parent/my-profile")
                      }
                      style={{
                        backgroundImage: "url(../assets/images/xs/avatar5.jpg)",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      className="avatar"
                    ></i>
                  )}
                  <Link
                    onClick={this.handleLogout}
                    className="nav-link icon settingbar"
                  >
                    <i className="fe fe-power" style={{ size: "50px" }}></i>
                  </Link>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  userLogoutAction: (e) => dispatch(userLogoutAction(e)),
  clearRoleAction: (e) => dispatch(clearRoleAction(e)),
});
export default connect(null, mapDispatchToProps)(Header);
