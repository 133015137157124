import React, { Component } from 'react';
import InstituteMenu from './InstituteMenu';

export default class InstituteLayout extends Component {
	render() {
		return (
			<div id="main_content">
				<InstituteMenu {...this.props} />
			</div>
		);
	}
}
