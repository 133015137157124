import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { userChangePasswordAction } from '../../../actions/institute/Authentication/userAuthenticationAction';
import "./style.scss";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdbreact";
import { isLogined } from "../../../utils/token";


class InstituteChangePassword extends Component {
	state = {
		passwordInput: {
		  password: "",
		  confirm_password: "",
		},
		openPasswordModal: false,
	  };
	passwordToggleClose = () => {
		this.setState({
		  openPasswordModal: false,
		});
	  };
	
	  handleProfilePasswordAlert = (event) => {
		event.preventDefault();
		event.target.className += " was-validated";
		if (event.target.checkValidity()) {
		  this.setState({
			openPasswordModal: true,
		  });
		}
	  };

	changePasswordHandler = event => {
        let passwordInput = this.state.passwordInput
        passwordInput[event.target.name] = event.target.value
		this.setState({ passwordInput:passwordInput });
	};
	changePasswordSubmitHandler = () => {
		const status = this.props.userChangePasswordAction(this.state.passwordInput)
		this.setState({
			changePasswordFlag: status
		},()=>{
			this.passwordToggleClose()
		})
	}

	modalContainer = () => {
		return (
		  <MDBContainer>
			<MDBModal
			  isOpen={this.state.openPasswordModal}
			  toggle={this.passwordToggleClose}
			>
			  <MDBModalHeader toggle={this.passwordToggleClose}>
				Alert
			  </MDBModalHeader>
			  <MDBModalBody>
				<h5>Are you sure you want to change password.?</h5>
			  </MDBModalBody>
			  <MDBModalFooter>
				<MDBBtn
				  color="secondary"
				  onClick={this.changePasswordSubmitHandler}
				>
				  Confirm
				</MDBBtn>
				<MDBBtn color="secondary" onClick={this.passwordToggleClose}>
				  Close
				</MDBBtn>
			  </MDBModalFooter>
			</MDBModal>
		  </MDBContainer>
		);
	  };
	render() {
		if (!isLogined()) this.props.history.push("/");
		return (
		  <>
			<div className="auth option2" style={{backgroundColor:'white'}}>
			  <div className="auth_left authenticationContainer">
				<div className="card">
				  <form onSubmit={this.handleProfilePasswordAlert} noValidate>
					<MDBContainer className="MDBContainer">
					  <MDBRow className="justify-content-center">
						<MDBCol>
						  <div className="card-body">
							<div className="text-center">
							  <Link className="header-brand">
								<i className="fa fa-graduation-cap brand-logo"></i>
							  </Link>
							  <div className="card-title mt-3">Change Password</div>
							</div>
							<div className="row">
							  <div className="col-lg-3 col-md-4 col-sm-6 inputField">
								<div className="input-group">New Password</div>
							  </div>
							  <div className="col-lg-1 col-md-4 col-sm-6 inputField">
								<div className="input-group">:</div>
							  </div>
							  <div className="col-lg-7 col-md-4 col-sm-6 inputField">
								<div className="input-group">
								  <input
									value={this.state.passwordInput["password"]}
									onChange={this.changePasswordHandler}
									name="password"
									type="password"
									className="form-control"
									placeholder="New Password"
									pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$"
									required
								  />
								  <div className="invalid-feedback">
									Min 8 char,Atleast one numaric, special char,
									alphabet.
								  </div>
								</div>
							  </div>
							</div>
							<div className="row">
							  <div className="col-lg-3 col-md-4 col-sm-6 inputField">
								<div className="input-group">Confirm Password</div>
							  </div>
							  <div className="col-lg-1 col-md-4 col-sm-6 inputField">
								<div className="input-group">:</div>
							  </div>
							  <div className="col-lg-7 col-md-4 col-sm-6 inputField">
								<div className="input-group">
								  <input
									value={
									  this.state.passwordInput.confirm_password
									}
									onChange={this.changePasswordHandler}
									name="confirm_password"
									type="password"
									className="form-control"
									placeholder="Confirm Password"
									pattern={this.state.passwordInput["password"]}
									required
								  />
								  <div className="invalid-feedback">
									Password are not matching.
								  </div>
								</div>
							  </div>
							</div>
							<MDBBtn color="primary" type="submit">
							  Create Password
							</MDBBtn>
							{this.state.changePasswordFlag && (
							  <div className="imageFlag">
								password changed successfully
							  </div>
							)}
							{this.state.changePasswordFlag === false && (
							  <div>password changed Failed</div>
							)}
						  </div>
						</MDBCol>
					  </MDBRow>
					</MDBContainer>
				  </form>
				</div>
			  </div>
			</div>
	
			{this.state.openPasswordModal && this.modalContainer()}
		  </>
		);
	  }
}

const mapDispatchToProps = dispatch => ({
	userChangePasswordAction: (e) => dispatch(userChangePasswordAction(e)),
})
export default connect(null, mapDispatchToProps)(InstituteChangePassword)
