import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { userForgotPasswordAction } from '../../../actions/institute/Authentication/userAuthenticationAction';
import { isLogined } from '../../../utils/token'
import "../style.scss";

class InstituteForgotpassword extends Component {
	state = {
		email: "",
		submitFlag: ''
	  };

	componentWillReceiveProps(nextProps) {

		if(this.state.submitFlag !== nextProps.submitFlag.status){
			this.setState({
				submitFlag : nextProps.submitFlag.status
			})
		}
	}

	submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
			const obj = {email : this.state.email }
			this.props.userForgotPasswordAction(obj)
		}
	}

	changeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		if(isLogined()) this.props.history.push('/institute/manage-class')
		return (
			<div className="auth option2">
				<div className="auth_left authenticationContainer">
					<div className="card">
						<form  onSubmit={this.submitHandler} noValidate>
						<MDBContainer className="MDBContainer">
							<MDBRow className="justify-content-center">
								<MDBCol>
									<div className="card-body">
										<div className="text-center title">
											<Link className="header-brand" to="/">
												<i className="fa fa-graduation-cap brand-logo"></i>
											</Link>
											<div className="card-title">Forgot password</div>
										</div>
										<div className="text-center">
											
										{this.state.submitFlag === "Email doesn't exists" ? 
											<p className="alertFlag">{this.state.submitFlag}</p> :
											<p className="text-muted successFlag">{this.state.submitFlag}</p>}
											
										</div>
										<div className="form-group inputContainer">
											{/* <label className="form-label" for="exampleInputEmail1">Email address</label> */}
											<input 
												value={this.state.email}
												name="email"
												onChange={this.changeHandler}
												type="email" 
												className="form-control" 
												id="inputEmail1" 
												aria-describedby="emailHelp" 
												placeholder="Enter email" 
												required
											/>
											<div className="invalid-feedback">
												Please provide a valid mail.
											</div>
										</div>
											<MDBRow className="justify-content-center">
												<MDBCol size="6">
													<MDBBtn color="primary" type="submit" className="MDBBtn" >
														Submit
													</MDBBtn>
												</MDBCol>
												<MDBCol size="6">
													<div className="text-center">
														<button type="submit" className="btn btn-primary btn-block" onClick={()=>{
															this.props.history.push('/')
														}}>Back</button>
													</div>
												</MDBCol>
											</MDBRow>
									</div>
								</MDBCol>
							</MDBRow>
						</MDBContainer> 
						</form>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	submitFlag : state.instituteUserForgotPasswordReducer,
})

const mapDispatchToProps = dispatch => ({
	userForgotPasswordAction: (e) => dispatch(userForgotPasswordAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(InstituteForgotpassword)
