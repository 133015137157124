import React from 'react';
import CKEditor from "react-ckeditor-component";

class Ckeditor extends React.Component {
    constructor(props) {
      super(props);
      this.blur = this.onBlur.bind(this);
      this.afterPaste = this.afterPaste.bind(this);
      this.onChange = this.onChange.bind(this);
  
      this.state = {
        html: this.props.html
      };
    }
  
    onChange(evt) {
        var html = evt.editor.getData();
        this.setState({ html });
        this.props.onChange(evt.editor.getData())
    }
  
    onBlur(evt) {
    }
    componentWillReceiveProps(nextProps){
      if (nextProps.html !== this.state.html) {
        this.setState({ html: nextProps.html });
      }
    }
    afterPaste(evt) {
    }
  
    render() {
      return (
        <CKEditor
          activeClass="p10"
          content={this.state.html}
          events={{
            blur: this.onBlur,
            afterPaste: this.afterPaste,
            change: this.onChange
          }}
        />
      );
    }
  }
  


export default Ckeditor;
